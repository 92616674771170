import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import pinia from './store'
import { useAppState } from './store/AppState'
import router from './plugins/router'
import VueGtag from 'vue-gtag'
import Rollbar from 'rollbar'

Vue.config.productionTip = false

Vue.use(VueGtag, {
	config: { id: "G-NJK49SPGT2" },
}, router)

const rollbarInstance = new Rollbar({
	accessToken: 'ef4dec6558714a298dc60b98bf9a9513',
	captureUncaught: true,
	captureUnhandledRejections: true,
})

Vue.prototype.$rollbar = rollbarInstance

Vue.config.errorHandler = (err, vm) => {
	Vue.$gtag.exception({
		description: err.message,
		fatal: true
	})

	vm.$rollbar.error(err)

	throw err
}

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_APIBASE,
	responseType: 'json',
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
})

const interceptorRequest = {}

axiosInstance.interceptors.request.use(
	config => {
		if (config.data instanceof FormData) {
			const fdp = Object.fromEntries(config.data)
			fdp.at_exist = fdp.at ? true : false
			delete fdp.at
			interceptorRequest.url = config.url
			interceptorRequest.data = fdp
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

axiosInstance.interceptors.response.use(
	response => {
		return response
	},
	error => {
		rollbarInstance.debug('Axios Interceptors', {
			request: interceptorRequest,
			response: {
				url: error.config.url,
				data: error.response.data,
			},
		})

		return Promise.reject(error)
	}
)

Vue.prototype.$api = axiosInstance

const someStorePlugin = {
	install (Vue, options) {
		Vue.prototype.store = useAppState(options.pinia)
	}
}

Vue.use(someStorePlugin, { pinia })

new Vue({
	vuetify,
	pinia,
	router,
	data: {
		ukuran: {
			picture_sample: 1000*1000*2,
			picture_population: 1000*1000*2,
		},
		str_ukuran: {
			picture_sample: 'Maksimal 2 MB',
			picture_population: 'Maksimal 2 MB',
		},
		prodMode: process.env.VUE_APP_PRODMODE,
		publicPath: process.env.BASE_URL,
		envWorkingPath: process.env.VUE_APP_HOMEDIR,
		thumbImage: process.env.VUE_APP_THUMBIMAGE + '?t=',
	},
	render: h => h(App)
}).$mount("#app")
